import clsx from "clsx";
import { useShowSidebar } from "../../../shared/Sidebar/useShowSidebar";

/**
 * Make the supplier page to take up the whole page, with a max of 75rem,
 * and be centered by PageSection's m-auto while accounting for "px-6 md:px-8".
 */
export function usePageWidthClass() {
  const showSidebar = useShowSidebar();
  return clsx("w-[calc(100vw-3rem)] md:w-[calc(100vw-5rem)]", {
    // -240px accounts for the sidebar
    "lg:w-[calc(100vw-5rem-240px)] xl:w-[74rem]": showSidebar,
    "lg:w-[calc(100vw-5rem)] xl:w-[75rem]": !showSidebar,
  });
}
